import React, { CSSProperties } from 'react'
import NextImage from 'next/image'
import StrapiImage from '../types/StrapiImage'
import { getStrapiMedia } from '../utils'

type ImageFormat = 'large' | 'medium' | 'small' | 'thumbnail'

interface ImageProps {
  image: StrapiImage
  className?: string
  objectFit?: 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
  priority?: boolean
  format?: ImageFormat
  imageStyle?: CSSProperties
}

const getImageUrl = (image: StrapiImage, format?: ImageFormat): string => {
  let url = image?.data?.attributes?.url
  if (format && image?.data?.attributes?.formats?.[format]) {
    url = image?.data?.attributes?.formats?.[format]?.url as string
  }
  if (url?.startsWith('/cms')) {
    return url
  }
  return getStrapiMedia(url) || ''
}

const Image = ({
  image,
  className,
  objectFit = 'contain',
  priority,
  format,
  imageStyle,
}: ImageProps) => (
  <div className={className}>
    <NextImage
      src={getImageUrl(image, format)}
      loader={() => getImageUrl(image, format)}
      // unoptimized
      alt={image?.data?.attributes?.alternativeText}
      width={image?.data?.attributes?.width}
      height={image?.data?.attributes?.height}
      style={{
        width: '100%',
        height: '100%',
        objectFit,
        ...(imageStyle || {}),
      }}
      priority={priority}
    ></NextImage>
  </div>
)

export default Image
