import { StrapiGlobal } from '../../types/StrapiGlobal'
import StrapiImage from '../../types/StrapiImage'
import DownloadButtons from '../downloadButtons'
import Image from '../Image'
import PageWidth from '../PageWidth'

interface HeroProps {
  coverImage: StrapiImage
  title: string
  subTitle: string
  showDownloadButtons: boolean
  global: StrapiGlobal
}

const Hero = ({
  coverImage,
  title,
  subTitle,
  showDownloadButtons,
  global,
}: HeroProps) => (
  <section className="blockHero">
    <Image
      image={coverImage}
      className="blockHero__image"
      objectFit="cover"
      priority
      format="large"
    ></Image>
    <div className="blockHero__imageOverlay"></div>
    <PageWidth style={{ zIndex: 2 }}>
      <div className="blockHero__columns">
        <div className="blockHero__column">
          <h1 className="blockHero__title">{title}</h1>
          <p className="blockHero__subtitle">{subTitle}</p>
          {showDownloadButtons && (
            <DownloadButtons downloadLinks={global?.downloadLinks} />
          )}
        </div>
        <div className="blockHero__column"></div>
      </div>
    </PageWidth>
  </section>
)

Hero.defaultProps = {}

export default Hero
