import Image from 'next/image'
import React from 'react'
import mixpanel from 'mixpanel-browser'
import { StrapiDownloadLinks } from '../types/StrapiGlobal'
import { getStrapiMedia } from '../utils'

interface downloadButtonsProps {
  downloadLinks: StrapiDownloadLinks
}

const IMAGE_SIZE = 220

const DownloadButtons = ({ downloadLinks }: downloadButtonsProps) => {
  const appStoreImage = downloadLinks?.appStoreImage?.data?.attributes
  const playStoreImage = downloadLinks?.playStoreImage?.data?.attributes

  return (
    <div className="downloadButtons">
      <a
        href={downloadLinks?.appStoreLink}
        onClick={() => {
          mixpanel.track('Download App', {
            store: 'App Store',
          })
        }}
      >
        <Image
          src={getStrapiMedia(appStoreImage?.url) || ''}
          loader={() => getStrapiMedia(appStoreImage?.url) || ''}
          alt={appStoreImage?.alternativeText}
          width={IMAGE_SIZE}
          height={(IMAGE_SIZE * appStoreImage.height) / appStoreImage.width}
        ></Image>
      </a>
      <a
        href={downloadLinks?.playStoreLink}
        onClick={() => {
          mixpanel.track('Download App', {
            store: 'Google Play',
          })
        }}
      >
        <Image
          src={getStrapiMedia(playStoreImage?.url) || ''}
          loader={() => getStrapiMedia(playStoreImage?.url) || ''}
          alt={playStoreImage?.alternativeText}
          width={IMAGE_SIZE}
          height={(IMAGE_SIZE * playStoreImage.height) / playStoreImage.width}
        ></Image>
      </a>
    </div>
  )
}

export default DownloadButtons
